import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { HTML_ALLOWED_TAGS_LIST } from "../config/config";
import { ENTRYPOINT } from "../config/entrypoint";

export enum SupportedLanguagesEnum {
  EN = "en",
  AR = "ar",
}

export const languagesOptions: { id: SupportedLanguagesEnum; label: string }[] =
  [
    {
      id: SupportedLanguagesEnum.EN,
      label: "English",
    },
    {
      id: SupportedLanguagesEnum.AR,
      label: "عربي",
    },
  ];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === "development",
    load: "currentOnly",
    defaultNS: "translation",
    detection: {
      order: ["querystring", "cookie", "header", "localStorage", "navigator"],
      caches: ["cookie"],
    },
    fallbackLng: SupportedLanguagesEnum.EN,
    supportedLngs: Object.values(SupportedLanguagesEnum),
    nsSeparator: "::",
    backend: {
      loadPath: `${ENTRYPOINT}/locales/{{lng}}/{{ns}}`, // Backend URL from which we load translations.
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: HTML_ALLOWED_TAGS_LIST,
    },
  });

export const isLngAR = () => i18n.language === SupportedLanguagesEnum.AR;
export const isLngEN = () => i18n.language === SupportedLanguagesEnum.EN;

export const placement = () => {
  return i18n.language === "ar" ? "left" : "right";
};

export const direction = (isRtl = false) => {
  return isRtl || i18n.language === "ar" ? "rtl" : "ltr";
};

export default i18n;
