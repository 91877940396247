import React, { useEffect } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutateAsyncFunction,
  useQueryClient,
} from "react-query";
import { useLogin, useLogout } from "../queries/auth";
import { useMeQuery } from "../queries/user";
import { LoginCredentials } from "../types/LoginCredentials";
import { Token } from "../types/Token";
import { User } from "../types/User";
import { setSession } from "../utils/jwt";
import ViolationError from "../utils/violationError";

interface AuthContextValue {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | undefined;
  login: UseMutateAsyncFunction<
    Token,
    Error | ViolationError,
    LoginCredentials
  >;
  logout: UseMutateAsyncFunction<any, any, void, any>;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  refetchUser: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<User, Error>>;
  setUser: (data: User) => User;
  error: Error | null;
}

const AuthContext = React.createContext<AuthContextValue | null>(null);
AuthContext.displayName = "AuthContext";

const AuthProvider: React.FC = (props) => {
  const queryClient = useQueryClient();
  const { data: user, error, isSuccess, isError, refetch } = useMeQuery();

  const setUser = React.useCallback(
    (data: User) => queryClient.setQueryData("getMe", data),
    [queryClient]
  );

  const loginMutation = useLogin(refetch);
  const logoutMutation = useLogout();

  const value = React.useMemo(
    () => ({
      isInitialized: isSuccess || isError,
      isAuthenticated: !!user,
      user,
      error,
      refetchUser: refetch,
      setUser,
      login: loginMutation.mutateAsync,
      isLoggingIn: loginMutation.isLoading,
      logout: logoutMutation.mutateAsync,
      isLoggingOut: logoutMutation.isLoading,
    }),
    [
      user,
      error,
      refetch,
      setUser,
      loginMutation.mutateAsync,
      loginMutation.isLoading,
      logoutMutation.mutateAsync,
      logoutMutation.isLoading,
    ]
  );

  useEffect(() => {
    const storageEventListener = async (event: StorageEvent) => {
      // Refetch user when we receive a login event from another window.
      if (event.key === "app-login") {
        await refetch();
      }

      // Logout user when we receive a logout event from another window.
      if (event.key === "app-logout") {
        setSession(null);
        queryClient.clear();
        await refetch();
      }
    };

    window.addEventListener("storage", storageEventListener);

    return () => {
      window.removeEventListener("storage", storageEventListener);
    };
  }, []);

  return <AuthContext.Provider value={value} {...props} />;
};

export { AuthContext, AuthProvider };
