import { Stack, Theme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Logo from "../Logo";
import { PATH_PAGE } from "../../pages/Routes/paths";
import { getExactPath } from "../../utils/route";
import LogoAR from "../Logo/LogoAr";
import { isLngAR } from "../../utils/i18n";
import LanguageSwitcher from "../LanguageSwitcher";
import { LANGUAGE_SWITCHER_ENABLED } from "../../config/config";

export default function LogoOnlyLayout() {
  const location = useLocation();
  return (
    <Stack direction="column" justifyContent="space-between" height="100vh">
      <Stack
        direction="row-reverse"
        sx={{
          position: "absolute",
          right: (theme: Theme) => theme.spacing(8),
          top: (theme: Theme) => theme.spacing(8),
        }}
      >
        {LANGUAGE_SWITCHER_ENABLED && <LanguageSwitcher />}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          position: "absolute",
          left: (theme: Theme) => theme.spacing(8),
          top: (theme: Theme) => theme.spacing(8),
        }}
      >
        {isLngAR() ? <LogoAR showText={false} /> : <Logo showText={false} />}
      </Stack>

      <Outlet />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 4, pr: 5, pb: 5, pl: 8 }}
      >
        {isLngAR() ? (
          <LogoAR
            showIcon={false}
            showText={!getExactPath(PATH_PAGE.page404, location.pathname)}
          />
        ) : (
          <Logo
            showIcon={false}
            showText={!getExactPath(PATH_PAGE.page404, location.pathname)}
          />
        )}
      </Stack>
    </Stack>
  );
}
