const path = (root: string, sublink: string) => {
  return `${root}${sublink}`;
};

export const PATH_AUTH = {
  login: "/login",
  verifyResetPasswordEmail: "/verify-reset-password-email",
  resetPassword: "/reset-password",
  setNewPassword: "/set-new-password/:id/:token",
};

export const PATH_PAGE = {
  home: "/",
  page404: "/404",
};

const ROOTS_COMPANIES = "/companies";
export const PATH_COMPANIES = {
  list: ROOTS_COMPANIES,
  detail: path(ROOTS_COMPANIES, "/:id"),
  creditAssessment: path(ROOTS_COMPANIES, "/:id/credit-assessment"),
  documents: path(ROOTS_COMPANIES, "/:id/documents"),
  loans: path(ROOTS_COMPANIES, "/:id/loans"),
  notes: path(ROOTS_COMPANIES, "/:id/notes"),
};

const ROOTS_LOANS = "/loans";
export const PATH_LOANS = {
  list: ROOTS_LOANS,
  detail: path(ROOTS_LOANS, "/:id"),
  installments: path(ROOTS_LOANS, "/:id/installments"),
  actions: path(ROOTS_LOANS, "/:id/actions"),
  documents: path(ROOTS_LOANS, "/:id/documents"),
  notes: path(ROOTS_LOANS, "/:id/notes"),
};

export const PATH_DASHBOARD = {
  root: "/dashboard",
};

export const PATH_AFTER_LOGIN = PATH_COMPANIES.list;
