import { lazy, Suspense } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Navigate, Route, Routes as ReactRoutes } from "react-router-dom";
import { RECAPTCHA_KEY } from "../../config/config";
import AuthGuard from "../../guards/AuthGuard";
import GuestGuard from "../../guards/GuestGuard";
import DashboardLayout from "../../components/Layouts/Dashboard";
import LogoOnlyLayout from "../../components/Layouts/LogoOnlyLayout";
import LoadingScreen from "../../components/LoadingScreen";
import { PATH_COMPANIES } from "./paths";

export const Loadable =
  (Component: React.LazyExoticComponent<React.ComponentType<any>>) =>
  (props: any) => {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };

const Routes = () => {
  return (
    <ReactRoutes>
      <Route
        element={
          <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={RECAPTCHA_KEY}
            scriptProps={{ async: true }}
          >
            <GuestGuard>
              <LogoOnlyLayout />
            </GuestGuard>
          </GoogleReCaptchaProvider>
        }
      >
        <Route path="login" element={<Login />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route
          path="verify-reset-password-email"
          element={<VerifyResetPasswordEmail />}
        />
        <Route
          path="reset-password-check/:id/:token"
          element={<ResetPasswordCheck />}
        />
        <Route
          path="set-new-password/:id/:token"
          element={<SetNewPassword />}
        />
      </Route>
      <Route
        element={
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        }
      >
        <Route
          element={<Navigate to={PATH_COMPANIES.list} replace />}
          index={true}
        />
        <Route path="companies">
          <Route path="" element={<CompaniesListPage />} />
          <Route path=":id" element={<CompaniesDetailPage />}>
            <Route path="" element={<CompaniesDetailTabDetail />} />
            <Route
              path="credit-assessment"
              element={<CompaniesDetailTabCreditAssessment />}
            />
            <Route path="documents" element={<CompaniesDetailTabDocuments />} />
            <Route path="loans" element={<CompaniesDetailTabLoans />} />
            <Route path="notes" element={<CompaniesDetailTabNotes />} />
          </Route>
        </Route>
        <Route path="loans">
          <Route path="" element={<LoansListPage />} />
          <Route path=":id" element={<LoansDetail />}>
            <Route path="" element={<LoansDetailTabDetail />} />
            <Route
              path="installments"
              element={<LoansDetailTabInstallments />}
            />
            <Route path="actions" element={<LoansDetailTabActions />} />
            <Route path="documents" element={<LoansDetailTabDocuments />} />
            <Route path="notes" element={<LoansDetailTabNotes />} />
          </Route>
        </Route>
      </Route>

      <Route path={"*"} element={<LogoOnlyLayout />}>
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>

      <Route path={"*"} element={<Navigate to="/404" replace />} />
    </ReactRoutes>
  );
};

const Login = Loadable(lazy(() => import("../Auth/Login")));
const ResetPassword = Loadable(lazy(() => import("../Auth/ResetPassword")));
const VerifyResetPasswordEmail = Loadable(
  lazy(() => import("../Auth/VerifyResetPasswordEmail"))
);
const ResetPasswordCheck = Loadable(
  lazy(() => import("../Auth/ResetPasswordCheck"))
);
const SetNewPassword = Loadable(lazy(() => import("../Auth/SetNewPassword")));

const CompaniesListPage = Loadable(lazy(() => import("../Companies/List")));
const CompaniesDetailPage = Loadable(lazy(() => import("../Companies/Detail")));
const CompaniesDetailTabDetail = Loadable(
  lazy(() => import("../Companies/Detail/Tabs/Detail"))
);
const CompaniesDetailTabCreditAssessment = Loadable(
  lazy(() => import("../Companies/Detail/Tabs/CreditAssessment"))
);
const CompaniesDetailTabDocuments = Loadable(
  lazy(() => import("../Companies/Detail/Tabs/Documents"))
);
const CompaniesDetailTabLoans = Loadable(
  lazy(() => import("../Companies/Detail/Tabs/Loans"))
);
const CompaniesDetailTabNotes = Loadable(
  lazy(() => import("../Companies/Detail/Tabs/Notes"))
);

const LoansListPage = Loadable(lazy(() => import("../Loans/List")));
const LoansDetail = Loadable(lazy(() => import("../Loans/Detail")));
const LoansDetailTabDetail = Loadable(
  lazy(() => import("../Loans/Detail/Tabs/Detail"))
);
const LoansDetailTabInstallments = Loadable(
  lazy(() => import("../Loans/Detail/Tabs/Installments"))
);
const LoansDetailTabActions = Loadable(
  lazy(() => import("../Loans/Detail/Tabs/Actions"))
);
const LoansDetailTabDocuments = Loadable(
  lazy(() => import("../Loans/Detail/Tabs/Documents"))
);
const LoansDetailTabNotes = Loadable(
  lazy(() => import("../Loans/Detail/Tabs/Notes"))
);

const NotFound = Loadable(lazy(() => import("../Page404")));

export default Routes;
