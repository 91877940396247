import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { getActivePath } from "../../../../utils/route";
import { CapitalIcon, HistoryIcon } from "../../../Icons";
import { PATH_COMPANIES, PATH_LOANS } from "../../../../pages/Routes/paths";

export default function Menu() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const menuItems = [
    {
      path: PATH_COMPANIES.list,
      icon: <CapitalIcon fill="none" sx={{ fill: "none" }} />,
      title: t("menu.main.companies"),
    },
    {
      path: PATH_LOANS.list,
      icon: <HistoryIcon fill="none" sx={{ fill: "none" }} />,
      title: t("menu.main.loans"),
    },
  ];
  return (
    <Box sx={{ mt: 4 }}>
      <List>
        {menuItems.map((item) => (
          <ListItemButton
            key={item.path}
            component={RouterLink}
            to={item.path}
            selected={getActivePath(item.path, pathname)}
            sx={{ mb: 1.5 }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}
