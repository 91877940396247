import React, { useState } from "react";
import i18n, { direction, isLngAR } from "./utils/i18n";
import RTL from "./components/LanguageSwitcher/RTL";
import ThemeProvider from "./theme";
import { ProgressBarStyle } from "./components/ProgressBar";
import Routes from "./pages/Routes";

export default function App() {
  const [isRtl, setIsRtl] = useState(isLngAR());
  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", direction(isRtl));
  }, [isRtl]);
  i18n.on("languageChanged", () => {
    setIsRtl(isLngAR());
  });
  return (
    <RTL>
      <ThemeProvider>
        <ProgressBarStyle />
        <Routes />
      </ThemeProvider>
    </RTL>
  );
}
