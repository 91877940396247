import { Box, Stack, SxProps, Theme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { PATH_PAGE } from "../../pages/Routes/paths";

export default function Logo({
  disabledLink = false,
  showIcon = true,
  showText = true,
  sxWrapper = [],
  sxIcon = [],
  sxText = [],
}: {
  disabledLink?: boolean;
  showIcon?: boolean;
  showText?: boolean;
  sxWrapper?: SxProps<Theme>;
  sxIcon?: SxProps<Theme>;
  sxText?: SxProps<Theme>;
}) {
  const logo = (
    <Stack
      component="span"
      direction="row"
      alignItems="center"
      sx={[{ pl: 2 }, ...(Array.isArray(sxWrapper) ? sxWrapper : [sxWrapper])]}
    >
      {showIcon && (
        <Box
          component="span"
          sx={[
            { width: 40, height: 63, "& > svg": { display: "inherit" } },
            ...(Array.isArray(sxIcon) ? sxIcon : [sxIcon]),
          ]}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 40 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.6142 15.2367C-0.871406 11.7511 -0.871399 6.09981 2.61421 2.61421C6.09982 -0.871402 11.7511 -0.871402 15.2367 2.61421L37.3857 24.7632C40.8713 28.2488 38.7751 32.0814 35.2895 35.567C31.8039 39.0527 28.2488 40.8713 24.7632 37.3857L2.6142 15.2367Z"
              fill="#05F1D8"
            />
            <path
              d="M2.67141 47.2516C-0.738033 50.661 -0.738026 56.1888 2.67141 59.5982C6.08085 63.0077 11.6086 63.0077 15.0181 59.5982L37.4429 37.1734C40.8523 33.764 40.8523 28.2362 37.4429 24.8267C34.0335 21.4173 28.5057 21.4173 25.0962 24.8267L2.67141 47.2516Z"
              fill="#05F1D8"
            />
            <path
              d="M36.0527 38.4845C32.5822 40.8215 27.8329 40.4552 24.7633 37.3855L18.6108 31.2331L25.1769 24.6669C28.1751 21.6688 32.8113 21.3071 36.2047 23.5819L37.3858 24.763C39.765 27.1422 40.5202 30.5304 39.6514 33.5502C39.2391 34.8166 38.5638 36.0639 37.5576 37.0701L36.7228 37.951L36.0527 38.4845Z"
              fill="#03BBA7"
            />
          </svg>
        </Box>
      )}

      {showText && (
        <Box
          component="span"
          sx={[
            {
              width: 86,
              height: 30,
              "& > svg": {
                display: "inherit",
                fill: (theme) => theme.palette.text.primary,
              },
              ...(showIcon && {
                marginLeft: "12px",
              }),
            },
            ...(Array.isArray(sxText) ? sxText : [sxText]),
          ]}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 86 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.75857 11.6067C3.33045 9.09166 6.20295 6.98193 11.1933 6.98193C16.3019 6.98193 19.0926 9.76168 20.5515 12.4971C21.271 13.8462 21.6709 15.1845 21.8919 16.1788C22.0028 16.678 22.0699 17.0962 22.1095 17.3932C22.1293 17.5419 22.1423 17.6606 22.1505 17.7445C22.1546 17.7865 22.1575 17.8198 22.1595 17.8437C22.1605 17.8557 22.1612 17.8654 22.1617 17.8727L22.1624 17.8818L22.1626 17.885L22.1627 17.8862C22.1627 17.8868 22.1628 17.8873 21.1676 17.9536L22.1628 17.8873L22.2337 18.951H2.04393C2.16578 20.2521 2.54682 22.045 3.53448 23.633C4.76589 25.6129 6.995 27.3737 11.1933 27.3737C15.4837 27.3737 17.4941 25.9246 18.4647 24.6606C18.9669 24.0066 19.2292 23.353 19.3654 22.8659C19.4334 22.623 19.4691 22.4245 19.4876 22.293C19.4968 22.2274 19.5016 22.179 19.504 22.1506C19.5052 22.1365 19.5058 22.1274 19.506 22.1237L19.506 22.1247L19.5059 22.1268L19.5059 22.128C19.5058 22.1287 19.5058 22.1293 20.5026 22.1649C21.4994 22.2005 21.4994 22.2013 21.4993 22.202L21.4993 22.2037L21.4991 22.2074L21.4987 22.2166C21.4985 22.222 21.4981 22.2283 21.4977 22.2356C21.4976 22.2376 21.4975 22.2397 21.4974 22.2418C21.4962 22.2616 21.4944 22.2874 21.4918 22.3187C21.4865 22.3812 21.4777 22.4661 21.4631 22.5702C21.4339 22.7781 21.3813 23.0645 21.2865 23.4034C21.0972 24.08 20.7361 24.9779 20.0469 25.8755C18.6348 27.7145 15.9905 29.3686 11.1933 29.3686C6.30399 29.3686 3.43515 27.2505 1.84053 24.6866C0.290827 22.1949 0 19.3805 0 17.9536C0 16.7547 0.233234 14.0472 1.75857 11.6067ZM19.5061 22.1223C19.5061 22.1214 19.5061 22.1218 19.506 22.1237L19.5061 22.1223ZM2.04935 16.9562H20.0164C19.9952 16.8476 19.9714 16.7324 19.9445 16.6115C19.7499 15.7356 19.4017 14.5804 18.7913 13.4359C17.5904 11.1842 15.394 8.97678 11.1933 8.97678C6.87438 8.97678 4.64895 10.7459 3.4502 12.6639C2.52206 14.149 2.16899 15.7935 2.04935 16.9562ZM32.4717 8.97678C29.8398 8.97678 27.7062 11.1104 27.7062 13.7423V28.5928H25.7114V13.7423C25.7114 10.0086 28.7381 6.98193 32.4717 6.98193H34.4665V8.97678H32.4717Z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M49.0954 8.97678C44.0152 8.97678 39.8969 13.0951 39.8969 18.1752C39.8969 23.2554 44.0152 27.3737 49.0954 27.3737C54.1756 27.3737 58.2939 23.2554 58.2939 18.1752C58.2939 13.0951 54.1756 8.97678 49.0954 8.97678ZM58.2939 24.555V29.3686H60.2887V18.1752C60.2887 11.9934 55.2773 6.98193 49.0954 6.98193C42.9135 6.98193 37.9021 11.9934 37.9021 18.1752C37.9021 24.3571 42.9135 29.3686 49.0954 29.3686C52.9063 29.3686 56.2724 27.4641 58.2939 24.555Z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M74.8066 27.3738C69.7264 27.3738 65.6081 23.2555 65.6081 18.1753C65.6081 13.0952 69.7264 8.97687 74.8066 8.97687C79.8868 8.97687 84.0051 13.0952 84.0051 18.1753C84.0051 23.2555 79.8868 27.3738 74.8066 27.3738ZM84.0051 11.7956V5.14984e-05H85.9999V18.1753C85.9999 24.3572 80.9885 29.3687 74.8066 29.3687C68.6247 29.3687 63.6133 24.3572 63.6133 18.1753C63.6133 11.9934 68.6247 6.98202 74.8066 6.98202C78.6175 6.98202 81.9836 8.88649 84.0051 11.7956Z"
            />
          </svg>
        </Box>
      )}
    </Stack>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={PATH_PAGE.home}>{logo}</RouterLink>;
}
