export const SITE_ENV = process.env.REACT_APP_SITE_ENV;

export const APP_NAME = "Erad";

export const NAVBAR = {
  DASHBOARD_WIDTH: 190,
};

export const HEADER = {
  MOBILE_HEIGHT: 55,
  DESKTOP_HEIGHT: 55,
};

export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_TRACES_SAMPLE_RATE =
  process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE;

export const SUPPORT_MAIL = "support@erad.co";
export const SUPPORT_WHATSAPP = "https://wa.me/966115102955";

export const HTML_ALLOWED_TAGS_LIST = [
  "br",
  "strong",
  "i",
  "p",
  "em",
  "u",
  "code",
  "s",
  "ul",
  "li",
  "ol",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
];
export const AR_HTML_ALLOWED_TAGS_LIST = [
  "br",
  "strong",
  "i",
  "p",
  "em",
  "u",
  "s",
  "ul",
  "li",
  "ol",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
];

export const DEFAULT_ALLOWED_MIME_TYPES = {
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
};

export const LANGUAGE_SWITCHER_ENABLED =
  process.env.REACT_APP_LANGUAGE_SWITCHER_ENABLED === "true";
