import { Logout as LogoutIcon } from "@mui/icons-material";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { NAVBAR } from "../../../../config/config";
import useAuth from "../../../../hooks/useAuth";
import { isLngAR } from "../../../../utils/i18n";
import Logo from "../../../Logo";
import LogoAR from "../../../Logo/LogoAr";
import Menu from "./Menu";

export default function Navbar() {
  const { t } = useTranslation();
  const { logout } = useAuth();

  const renderContent = (
    <Box
      sx={{
        height: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLngAR() ? (
        <LogoAR
          sxIcon={{ width: 15, height: 24 }}
          sxText={{ width: 47, height: 16 }}
        />
      ) : (
        <Logo
          sxIcon={{ width: 15, height: 24 }}
          sxText={{ width: 47, height: 16 }}
        />
      )}

      <Menu />

      <Box sx={{ flexGrow: 1 }} />

      <List sx={{ p: 0 }}>
        <ListItemButton onClick={async () => await logout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("global.logout")}
            primaryTypographyProps={{ variant: "h5" }}
          />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <Drawer
      open
      variant="persistent"
      PaperProps={{
        sx: {
          width: NAVBAR.DASHBOARD_WIDTH,
          p: "20px 12px 40px",
          borderRight: 0,
        },
      }}
    >
      {renderContent}
    </Drawer>
  );
}
