import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { NAVBAR } from "../../../config/config";
import Header from "./Header";
import Navbar from "./Navbar";

export default function Dashboard() {
  return (
    <>
      <Box>
        <Header />
        <Navbar />
        <Box
          component="main"
          sx={{
            width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
            marginLeft: `${NAVBAR.DASHBOARD_WIDTH + 1}px`,
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <Stack
        direction="row-reverse"
        sx={{
          position: "fixed",
          bottom: 30,
          right: 0,
          pr: 1,
        }}
      ></Stack>
    </>
  );
}
