import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function RefundIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_202_686)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25797 8.66992C4.61265 5.00251 8.14043 2.38965 12.276 2.38965C17.5837 2.38965 21.8864 6.69235 21.8864 12C21.8864 17.3077 17.5837 21.6104 12.276 21.6104C8.01103 21.6104 4.39249 18.8314 3.13725 14.9828L1.23582 15.603C2.75154 20.2502 7.12007 23.6104 12.276 23.6104C18.6882 23.6104 23.8864 18.4122 23.8864 12C23.8864 5.58779 18.6882 0.389648 12.276 0.389648C7.24867 0.389648 2.96995 3.58427 1.35453 8.05175L0 7.61185L1.05476 12.5706L4.8218 9.17779L3.25797 8.66992Z"
          fill="currentColor"
        />
        <path
          d="M15.1716 10.3333V10.3333C15.1716 9.41286 14.4254 8.66667 13.505 8.66667H12.1716M9.17163 13.6667V13.6667C9.17163 14.5871 9.91782 15.3333 10.8383 15.3333H12.1716M12.1716 8.66667H10.8383C9.91782 8.66667 9.17163 9.41286 9.17163 10.3333V10.3333C9.17163 11.2538 9.91782 12 10.8383 12H13.505C14.4254 12 15.1716 12.7462 15.1716 13.6667V13.6667C15.1716 14.5871 14.4254 15.3333 13.505 15.3333H12.1716M12.1716 8.66667V6.5M12.1716 15.3333V17.5"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_202_686">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
